var detailURL = "http://www3.golfyoyaku.jp/rsv_sys/j-golf/kasumigaura/mcalendar/index";
$(document).ready(function(){
    $.getJSON("http://www3.golfyoyaku.jp/rsv_sys/mweb_services/search_plan_multi/j-golf/kasumigaura/term:120/maxNum:3?callback=?",
    function(data, status){
        var szBuff = "<table summary='プラン一覧表' class='planTable'>\n<tbody>\n";
        var view_count = 0;
        for (var i=0 ; i<data[1].length ; i++) {
            var plan_id = data[1][i];
            var plan_info = data[4][plan_id];
            if(view_count >= 5){
                break;
            }
            var plan_detail_id = plan_info.id;

            // console.log(plan_info.is_recommended);
            // if(plan_info.is_recommended != '1'){
            //     continue;
            // }

            console.log(data[3][351][plan_id]);

            //プランに紐づく空き枠日付は最初のキー
            var first_open_tee_time_date = '';
            for ( var key in data[3][351][plan_id] ) {
                first_open_tee_time_date = key.toString().substring(0,6);
                break;
            }

            //使用するプラン名の判定
            var plan_name = '';
//            if(plan_info.disp_plan_name.length > 0){
//                plan_name = plan_info.disp_plan_name;
//            }else{
                plan_name = plan_info.plan_name;
//            }
            szBuff += "<tr>\n";
            szBuff += "<td class='plan' style='font-size:90%'><span>" + plan_name + "</span></td>\n";
            szBuff += "<td class='price'>\\" + set_comma3(plan_info.price_total) + "</td>\n"
            szBuff += "<td class='calendar'><a href='javascript:void(0);' onclick='open_detail(" + plan_detail_id + ", " + first_open_tee_time_date + ");'><img src='img/index_icon_05.gif' /></a></td>\n";
            szBuff += "</tr>\n";
            view_count++;
        }
       szBuff += "</tbody>\n</table>\n";
       $("#webReserve").html(szBuff);
    });
});
function set_comma3(target) {
    var num = new String(target).replace(/,/g, "");
    while(num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    return num;
}
function open_detail(plan_detail_id, first_open_tee_time_date) {

    // 現在月とプラン開始日を比較して大きい方を採用して使用する。
    var url_start = '';
    var date = new Date();
    var now_month = date.getFullYear() + toDoubleDigits(date.getMonth()+1);
    if(now_month >= first_open_tee_time_date){
        url_start = '/filter:all';
    }else{
        url_start = '/start:' + first_open_tee_time_date;
    }

    var setURL = self.detailURL + "/plan:" + plan_detail_id + url_start;
    window.open(setURL, "_blank");
}

// 1桁の数字を0埋めで2桁にする
function toDoubleDigits(num) {
  num += "";
  if (num.length === 1) {
    num = "0" + num;
  }
 return num;
};